div.orgInfo {
  display: inline-block;
}

div.orgMetadata {
  padding-left: 10px;
}

.imgLogo {
  float: left;
  padding-left: 8px;
  padding-bottom: 4px;
  display: inline-block;
}

.organizationNameSmall {
  font-size: 15px;
}
.organizationNameMedium {
  font-size: 20px;
  font-weight: bold;
}
.organizationNameLarge {
  font-size: 25px;
  font-weight: bold;
}

.organizationTagLineSmall {
  font-size: 10px;
}
.organizationTagLineMedium {
  font-size: 15px;
}
.organizationTagLineLarge {
  font-size: 20px;
}